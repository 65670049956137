import React from 'react';
import './single-video.scss';

export default class SingleVideo extends React.Component {

	getPosition() {
		return this.props.position;
	}

	render() {
		if(this.props.position === 0) {
			return (
					<section className={"single-video"}>
						<div className={"container"}>
							<Caption position={this.getPosition()} video={this.props.video} />
							<Video video={this.props.video} _playCallback={this.props.playCallback} />
						</div>
					</section>
			);
		} else {
			return (
					<section className={"single-video"}>
						<div className={"container left"}>
							<Video video={this.props.video} _playCallback={this.props.playCallback} />
							<Caption position={this.props.position} video={this.props.video} />
						</div>
					</section>
			);
		}

	}
}

const Caption = (props) => {
	if(props.position === 0) {
		return (
				<div className={"caption col-md-4"} style={{backgroundColor: props.video.back, color: props.video.color}}>
					<h4>{props.video.title}</h4>
					<p>{props.video.par}</p>
					<span className={"index"} style={{backgroundColor: props.video.back, color: props.video.color}}>{props.video.n}</span>
				</div>
		);
	} else {
		return (
				<div className={"caption left col-md-4"} style={{backgroundColor: props.video.back, color: props.video.color}}>
					<h4>{props.video.title}</h4>
					<p>{props.video.par}</p>
					<span className={"index"} style={{backgroundColor: props.video.back, color: props.video.color}}>{props.video.n}</span>
				</div>
		);
	}
};

const Video = (props) => {
	return (
			<div className={"videobox col-md-8"}>
				<video poster={props.video.poster} onPlay={props._playCallback} controls muted>
					<source src={props.video.video}/>
				</video>
			</div>
	)
};