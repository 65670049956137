import React from 'react';
import HomePage from "./pages/home/Home";

function App() {
  return (
    <HomePage />
  );
}

export default App;
