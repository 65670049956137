const videos = [
	{
		title: "I tuoi risparmi: li lasci sotto il materasso o li trasformi in futuro?\n",
		par: "Investire significa dare vita\n" +
				"al tuo denaro e farlo crescere\n" +
				"nel tempo, anziché lasciarlo\n" +
				"parcheggiato. Ti aiuterà\n" +
				"a realizzare i tuoi progetti di vita\n" +
				"e il tuo portafoglio ti ringrazierà.",
		n: 1,
		back: '#FFA600',
		color: '#000000',
		video: './assets/video/SCH_VIDEO_PILLOLA_1.mp4',
		poster: './assets/video/1.png'
	},
	{
		title: "Quando investi, scegli la sostenibilità\n",
		par: "Investire sostenibile significa\n" +
				"scegliere aziende gestite in modo\n" +
				"responsabile e che rispettano\n" +
				"l’ambiente e le persone.\n" +
				"Per questo, sono destinate\n" +
				"a prosperare e ciò premierà\n" +
				"gli investitori che hanno\n" +
				"creduto in loro.",
		n: 2,
		back: '#002A5E',
		color: '#ffffff',
		video: './assets/video/SCH_VIDEO_PILLOLA_2.mp4',
		poster: './assets/video/2.png'
	},
	{
		title: "ESG: dammi tre parole",
		par: "Alcuni pensano si tratti solo\n" +
				"di ecologia o di etica, altri\n" +
				"di una moda. In realtà i criteri\n" +
				"di sostenibilità, o ESG\n" +
				"- Environmental, Social,\n" +
				"Governance - sono una scelta\n" +
				"obbligata e un’opportunità\n" +
				"per aziende e investitori.",
		n: 3,
		back: '#0074B7',
		color: '#ffffff',
		video: './assets/video/SCH_VIDEO_PILLOLA_3.mp4',
		poster: './assets/video/3.png'
	},
	{
		title: "“E” come Emergenza clima",
		par: "Facciamo un esempio.\n" +
				"Il riscaldamento globale è\n" +
				"una sfida epocale. Ci sono aziende\n" +
				"che la affrontano meglio di altre\n" +
				"o che la trasformano in un business.\n" +
				"Indirizzare i risparmi in queste\n" +
				"imprese può rivelarsi profittevole.\n" +
				"E farti sentire una persona migliore.",
		n: 4,
		back: '#BAE659',
		color: '#002A5E',
		video: './assets/video/SCH_VIDEO_PILLOLA_4.mp4',
		poster: './assets/video/4.png'
	},
	{
		title: "“E” come Emergenza plastica",
		par: "Anche lo stop alla plastica sta\n" +
				"rivoluzionando il modo di fare\n" +
				"impresa. Pensa ai produttori\n" +
				"di sacchetti! Come investitore\n" +
				"scegli aziende e settori pronti\n" +
				"al cambiamento. Così facendo, puoi\n" +
				"conciliare guadagno ed ecologia.",
		n: 5,
		back: '#0074B7',
		color: '#ffffff',
		video: './assets/video/SCH_VIDEO_PILLOLA_5.mp4',
		poster: './assets/video/5.png'
	},
	{
		title: "“S” come Stop allo zucchero",
		par: "Alimenti zuccherati: istituzioni\n" +
				"e consumatori dicono no.\n" +
				"Aumentano i rischi e i costi\n" +
				"per i produttori, con effetti sulle\n" +
				"valutazioni azionarie e sugli\n" +
				"investimenti. La salute prima\n" +
				"di tutto. Anche quella del portafoglio!",
		n: 6,
		back: '#FFA600',
		color: '#002A5E',
		video: './assets/video/SCH_VIDEO_PILLOLA_6.mp4',
		poster: './assets/video/6.png'
	},
	{
		title: "“S” come Società sostenibile",
		par: "Avere a cuore le persone è\n" +
				"importante quanto\n" +
				"l’ambiente. Significa rispettare\n" +
				"i clienti, i dipendenti, i fornitori\n" +
				"e la comunità in cui si opera:\n" +
				"le aziende che non si adeguano\n" +
				"resteranno indietro.",
		n: 7,
		back: '#B71962',
		color: '#FFFFFF',
		video: './assets/video/SCH_VIDEO_PILLOLA_7.mp4',
		poster: './assets/video/7.png'
	},
	{
		title: "“G” come Governance",
		par: "Conflitti di interesse? Corruzione?\n" +
				"Un’azienda così verrà penalizzata\n" +
				"dal mercato. Legalità, equità\n" +
				"e trasparenza hanno una valenza\n" +
				"morale, ma anche economica:\n" +
				"sono aspetti che un investitore\n" +
				"non può tralasciare.",
		n: 8,
		back: '#14E6F0',
		color: '#002A5E',
		video: './assets/video/SCH_VIDEO_PILLOLA_8.mp4',
		poster: './assets/video/8.png'
	},
	{
		title: "Come si diventa investitore sostenibile?",
		par: "Ci vuole una società di investimento\n" +
				"che impieghi i tuoi risparmi in linea\n" +
				"con i criteri ESG e che incoraggi\n" +
				"le imprese di cui è azionista\n" +
				"ad adottare pratiche sempre\n" +
				"più sostenibili. È il ruolo\n" +
				"dell’Asset Manager.",
		n: 9,
		back: '#0074B7',
		color: '#FFFFFF',
		video: './assets/video/SCH_VIDEO_PILLOLA_9.mp4',
		poster: './assets/video/9.png'
	},
	{
		title: "Un circolo virtuoso",
		par: "Cambiare il mondo si può, come\n" +
				"persone, come consumatori, ma\n" +
				"anche come investitori. Con\n" +
				"la finanza sostenibile indirizzi\n" +
				"i tuoi risparmi verso gli innovatori\n" +
				"che migliorano le cose. E così,\n" +
				"coniughi il tuo benessere\n" +
				"con quello collettivo.",
		n: 10,
		back: '#BAE659',
		color: '#002A5E',
		video: './assets/video/SCH_VIDEO_PILLOLA_10.mp4',
		poster: './assets/video/10.png'
	}
];

export default videos;