import React from 'react';
import './home.scss';
import Header from "../../components/Header/";
import SingleVideo from "../../components/SingleVideo/SingleVideo";
import Banner from "../../components/Banner/Banner";
import GradientButton from "../../components/GradientButton";
import Footer from "../../components/Footer";
import videos from './videos';

const secondMessage= "Siamo una società d’investimento globale da oltre 2 secoli e 7 generazioni.\n" +
		"Abbiamo a cuore la sostenibilità: i criteri ESG sono parte integrante del nostro approccio e grazie ai nostri fondi d’investimento consentiamo anche ai piccoli risparmiatori di diventare investitori sostenibili.\n" +
		"Ecco perché abbiamo ottenuto il rating massimo A+ dall'organizzazione PRI (Principi per l’Investimento Responsabile) delle Nazioni Unite per 5 anni consecutivi e il primo posto nella classifica delle società d'investimento di ShareAction.";

export default class HomePage extends React.Component {
	state = {
		videoPlays: 1
	};

	constructor(props) {
		super(props);
		this.videoPlayed = this.videoPlayed.bind(this);
	}

	videoPlayed() {
		this.setState({videoPlays: this.state.videoPlays + 1});
		if(!window.gtag)
			return
		if(this.state.videoPlays === 2) {
			window.gtag('event', 'conversion', {
				'allow_custom_scripts': true,
				'send_to': 'DC-8443988/emeah0/schro003+standard'
			});
		}

	}

	render() {
		return (
				<div className={"homepage"}>
					<Header />

					<Banner title={"10 motivi per investire sulla sostenibilità"} />

					<SingleVideo playCallback={this.videoPlayed} position={0} video={videos[0]} />
					<SingleVideo playCallback={this.videoPlayed} position={1} video={videos[1]} />
					<SingleVideo playCallback={this.videoPlayed} position={0} video={videos[2]} />
					<SingleVideo playCallback={this.videoPlayed} position={1} video={videos[3]} />
					<SingleVideo playCallback={this.videoPlayed} position={0} video={videos[4]} />
					<SingleVideo playCallback={this.videoPlayed} position={1} video={videos[5]} />
					<SingleVideo playCallback={this.videoPlayed} position={0} video={videos[6]} />
					<SingleVideo playCallback={this.videoPlayed} position={1} video={videos[7]} />
					<SingleVideo playCallback={this.videoPlayed} position={0} video={videos[8]} />
					<SingleVideo playCallback={this.videoPlayed} position={1} video={videos[9]} />

					<Banner additionalClass={"prefooter"} title={"Schroders lo fa sostenibile"} message={secondMessage} />

					<div className={"col-12"}>
						<div className={"container prefooter"} style={{lineHeight: 0, textAlign: 'center'}}>
							<GradientButton text={"Scopri cosa facciamo"} link={"https://www.schroders.com/it/it/"} />
							<img src={"./assets/img/prefooter.png"} className={"mobile-hidden"} style={{width: '100%'}} />
							<img src={"./assets/img/prefooter.png"} className={"desktop-hidden"} style={{width: '100%'}} />
						</div>
					</div>

					<Footer />
				</div>
		)
	}
}