import React from 'react';
import './footer.scss';

export default class Footer extends React.Component {
	render() {
		return (
			<footer>
				<div class="container">
					<p>Fonte dei riconoscimenti: Report PRI (Principi per l'Investimento Responsabile) 2015, 2016, 2017, 2018 e 2019; ShareAction, “Lifting the Lid: Responsible Investment Performance of European Asset Managers”</p>
					<h4>MATERIALE DI MARKETING</h4>
					<p>Il valore degli investimenti e il reddito che ne deriva possono sia aumentare che diminuire e gli investitori potrebbero non recuperare l’importo investito in origine.
						Queste informazioni non rappresentano né una sollecitazione né una raccomandazione all’acquisto o alla vendita di strumenti finanziari o all’adozione di una strategia d’investimento.</p>
					<p>Diritti riservati in tutti i paesi</p>
					<p>PRIMA DELL’ADESIONE LEGGERE IL PROSPETTO INFORMATIVO. Tale prospetto è disponibile su <a style={{color: "#fff"}} href={"https://www.schroders.com/it/it/investitori-privati/come-sottoscrivere/documenti-legali/ "}>questo sito</a> e presso i collocatori autorizzati.</p>
					<p>Schroder Investment Management (Europe) S.A., Succursale italiana
						Via della Spiga, 30 - 20121 Milano
						Codice fiscale e n. iscrizione al Registro Imprese  n. 97833310150
						Partita IVA 10855070966
						Iscritta all'Albo delle SGR di cui all'art. 35 del D.Lgs. 58/98, sezione Gestori GEFIA e UCITS</p>
				</div>
			</footer>
		);
	}
}