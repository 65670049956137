import React from 'react';
import './header.scss';

export default class Header extends React.Component {

	render() {
		return(
				<header>
					<div className={"topbar"}>
						<div className={"container"}>
							<a href={"https://www.schroders.com/it/it/"}><img className={"logo"} src={"./assets/img/logo.png"} /></a>
						</div>
					</div>
					<div className={"container hero-schroders"}>
						<div className={"header-writings"}>
							<div className={"content"}>
								<div className={"payoff"}>
									<a href={"https://www.schroders.com/it/it/"}><img src={"./assets/img/logo-sym.png"} /></a>
									<h2>Investire ti rende.<br/>Anche un eroe.</h2>
								</div>
								<div className={"par"}>
									Libera i tuoi risparmi dalla trappola del conto corrente.
									Per metterli a frutto bisogna investirli e oggi puoi farlo in modo sostenibile.
									Così, fai bene al tuo portafoglio
									e al mondo intero.
								</div>
							</div>
						</div>
						<img className={"hero-img mobile-hidden"} src={"./assets/img/header.png"} />
						<img className={"hero-img desktop-hidden"} src={"./assets/img/header-mob.png"} />

						<img src={"./assets/img/hero.gif"} className={"hero-gif"} style={{ width: 'auto'}}/>
					</div>
				</header>
		)
	}
}