import React from 'react';
import './banner.scss';

export default class Banner extends React.Component {
	render() {
		if(!this.props.message)
			return (
					<section className={"banner " + this.props.additionalClass}>
						<div className={"container"}>
							<h2>{this.props.title}</h2>
						</div>
					</section>
			);
		else
			return (
					<section className={"banner " + this.props.additionalClass}>
						<div className={"container"}>
							<h2>{this.props.title}</h2>
							<p>{this.props.message}</p>
						</div>
					</section>
			);
	}
}